import React, { useState, useEffect, useCallback, useImperativeHandle } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { getTokenRedirect, publicClientApplication, scopes } from "./authentication/PublicClientApplication";

const App = React.forwardRef((props, applicationRef) => {
  useImperativeHandle(applicationRef, () => ({    
    getObjectId: () => {      
      return bizExaminerData === null ? "" : bizExaminerData.exmBookingsId;
    },     
    getObjectUrl: () => {
      return bizExaminerData === null ? "https://www.fh-krems.ac.at" : bizExaminerData.directAccessExamUrl;
    },  
    uploadFile: (objectId, name, type, blob) => {       
      console.log("uploading " + type + " '" + name + "' for exam " + objectId);
  
      const blobServiceClient = new BlobServiceClient(bizExaminerData.proctoringUploadUrl);      
      const containerClient = blobServiceClient.getContainerClient("");
      const blobName = name + "_" + objectId + "_" + new Date().getTime() + (type === "image" ? ".jpg" : ".wav");
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      blockBlobClient.uploadData(blob);
  
      console.log(`uploaded block blob ${blobName}`);
    }
  }));

  const [accountInfo, setAccountInfo] = useState(null);
  const [bizExaminerData, setBizExaminerData] = useState({});    

  const fetchData = useCallback(async (examId) => {
    if (accountInfo) {
      const token = await getTokenRedirect(accountInfo);
      if (token !== null && examId !== 0) {
        fetch(`${process.env.REACT_APP_API_BASEURL}/students/exams/GetBizExaminerBooking?exmBookingsId=${examId}`, {
          method: 'get',
          headers: new Headers(
            {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'          
            })
        })
        .then(res => res.json())
        .then(data => setBizExaminerData(data))
        .catch(() => null);  
      }    
    }
  }, [accountInfo]);
  
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examid');    
    fetchData(examId);
  }, [fetchData]);

  useEffect(() => {
		// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v1-migration.md#3-update-your-code
		(async () => {
			const tokenReponse = await publicClientApplication.handleRedirectPromise()
			if (tokenReponse)
				setAccountInfo(tokenReponse.account)
			else {
				const accounts = publicClientApplication.getAllAccounts()

				if (accounts.length === 1)
					setAccountInfo(publicClientApplication.getAllAccounts()[0])

				if (accounts.length === 0)
					publicClientApplication.loginRedirect({ scopes })
			}
		})()
    }, []);

  return (
          accountInfo &&
            <div>
              <p>Account: <b>{accountInfo?.name} ({accountInfo?.username.toLowerCase()})</b></p>
            </div>
);
  });

export default App;